#banner {
  padding-top: 6rem;
  padding-bottom: 20px;
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(../../assets/images/banner/1.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  .is-overlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgb(0 0 0 / 80%);
  }
  ul {
    > li {
      list-style: none;
      font-size: 30px;
      z-index: 10;
      color: $whiteColor;
    }
  }
  .slash {
    background-color: $whiteColor;
    width: 4px;
    height: 24px;
    transform: rotate(11deg);
    margin: 0 15px;
  }
}

.wave {
  width: 100%;
  background-attachment: fixed;
  position: absolute;
  width: 100%;
  /*height from the svg file - https://www.svgbackgrounds.com/tools/svg-to-css/*/
  height: 65px;
  top: -65px;
  left: 0;
  background: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1600px" height="65px" viewBox="0 0 1600 65" enable-background="new 0 0 1600 65" xml:space="preserve"><path fill="%23FFFFFF" d="M1600,34.766c-301.576,0-432.388-35.07-821.333-34.764C390.789,0.002,280.243,34.766,0,34.766V65h1600C1600,65,1600,64.416,1600,34.766z"/></svg>');
  z-index: 99;
  animation: waves 20s linear infinite;
}
.wave:before,
.wave:after {
  content: "";
  width: 100%;
  height: 65px;
  background: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1600px" height="65px" viewBox="0 0 1600 65" enable-background="new 0 0 1600 65" xml:space="preserve"><path fill="%23FFFFFF" d="M1600,34.766c-301.576,0-432.388-35.07-821.333-34.764C390.789,0.002,280.243,34.766,0,34.766V65h1600C1600,65,1600,64.416,1600,34.766z"/></svg>');
  position: absolute;
  top: 0;
  left: 0;
}
.wave:before {
  opacity: 0.4;
  animation: waves-reverse 20s linear infinite;
}
.wave:after {
  opacity: 0.6;
  animation-delay: -5s;
  animation: waves 30s linear infinite;
}
.wave.hero {
  top: auto;
  bottom: 0;
}
.wave.footer {
  transform: scale(-1, -1);
  top: 0;
}
.wave.gray {
  background: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1600px" height="65px" viewBox="0 0 1600 65" enable-background="new 0 0 1600 65" xml:space="preserve"><path fill="%23F9FAFB" d="M1600,34.766c-301.576,0-432.388-35.07-821.333-34.764C390.789,0.002,280.243,34.766,0,34.766V65h1600C1600,65,1600,64.416,1600,34.766z"/></svg>');
}
.wave.gray:before,
.wave.gray:after {
  background: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="utf-8"%3F><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1600px" height="65px" viewBox="0 0 1600 65" enable-background="new 0 0 1600 65" xml:space="preserve"><path fill="%23F9FAFB" d="M1600,34.766c-301.576,0-432.388-35.07-821.333-34.764C390.789,0.002,280.243,34.766,0,34.766V65h1600C1600,65,1600,64.416,1600,34.766z"/></svg>');
  position: absolute;
}

@keyframes waves {
  0% {
    background-position: 0;
  }
  100% {
    /* from width of the svg file */
    background-position: 1600px;
  }
}
@keyframes waves-reverse {
  0% {
    background-position: 1600px;
  }
  100% {
    background-position: 0;
  }
}
