html[dir="rtl"] {
  * {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }

  @mixin overlay-background($color, $duration) {
    left: auto;

    right: 0 !important;
  }

  #sidebar {
    right: -100%;
    &.active {
      right: 0;
    }
  }

  .main-heading,
  input::placeholder,
  .subtitle {
    text-align: right;
  }

  .settings {
    padding: 0 20px 0 0;
    &::before {
      content: "";
      position: absolute;
      top: 0 !important;
      left: auto !important;
      right: 0;
    }
  }

  .navbar {
    .position-relative .menu-langs {
      left: 35px;
    }
    .drop-down-menu {
      left: auto;
      right: 19px;
    }

    .nav-items {
      .nav-item {
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }

  #hero {
    .hero-content {
      text-align: right;
    }
    .signature {
      display: none;
    }
    .main-content {
      .buttons {
        gap: 2rem;
      }
    }
  }

  #about {
    .description {
      font-size: 18px;
    }
  }

  #wa {
    .wa-icon {
      left: auto;
      right: 20px;
    }

    .wa-txt {
      right: 123px;
      left: auto;
      &.active {
        left: auto;
        right: 105px;
      }
    }
  }

  .contact ul li span {
    padding-right: 1rem;
  }

  footer {
    .text-white,
    a,
    p {
      text-align: right !important;
    }
  }
}
