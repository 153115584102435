#sidebar {
  @media screen and (max-width: 900px) {
    width: 50%;
  }
  @media screen and (max-width: 780px) {
    width: 100%;
  }
}

.default-padding {
  @media screen and (width: 800px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  @media screen and (width: 780px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (width: 500px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.default-padding-top {
  @media screen and (width: 500px) {
    padding-top: 3rem;
  }
}

.default-padding-bottom {
  @media screen and (width: 500px) {
    padding-bottom: 3rem;
  }
}

.btn-custom {
  button {
    @media screen and (max-width: 600px) {
      padding: 15px 40px;
      font-size: 13px;
    }
  }
}

.title {
  @media screen and (max-width: 1200px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 1000px) {
    font-size: 40px;
  }
  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
  }
  @media screen and (max-width: 300px) {
    font-size: 20px;
    font-weight: 500;
  }
  @media screen and (max-width: 200px) {
    font-size: 18px;
    font-weight: 500;
  }
}

.main-heading {
  @media screen and (max-width: 1000px) {
    font-size: 40px;
  }
  @media screen and (max-width: 800px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    font-size: 25px;
  }
  @media screen and (max-width: 300px) {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  @media screen and (max-width: 200px) {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}

.subtitle {
  @media screen and (max-width: 1000px) {
    font-size: 18px;
  }
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
  @media screen and (max-width: 300px) {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
  }
  @media screen and (max-width: 200px) {
    font-size: 8px;
    font-weight: 300;
    text-align: center;
  }
}

.navbar {
  @media screen and (max-width: 1200px) {
    width: 100%;
    background-color: #fff;
  }
  @media screen and (max-width: 780px) {
    width: 100%;
    padding: 20px 10px;
  }
  .brand-logo {
    @media screen and (max-width: 1330px) {
      width: 80px;
    }
  }
  .mobile-lang {
    @media screen and (max-width: 1330px) {
      display: flex !important;
    }
  }
  .bar-icon {
    @media screen and (max-width: 1330px) {
      display: block;
      font-size: 30px;
    }
  }
  .nav-items {
    @media screen and (max-width: 1330px) {
      display: none;
    }
  }
}

#hero {
  @media screen and (max-width: 500px) {
    height: 80vh;
  }
  .herobike {
    @media screen and (max-width: 900px) {
      bottom: 0;
      width: 30%;
    }
  }
  .signature {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .container {
    .main-content {
      @media screen and (max-width: 1000px) {
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
      h1 {
        @media screen and (max-width: 1115px) {
          font-size: 80px;
          width: 80%;
          margin: auto;
        }
        @media screen and (max-width: 780px) {
          font-size: 3rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 2.5rem;
        }
        @media screen and (max-width: 500px) {
          font-size: 2rem;
        }
        @media screen and (max-width: 400px) {
          font-size: 30px;
        }
      }
      p {
        @media screen and (max-width: 1115px) {
          font-size: 20px;
          width: 80%;

          margin: 20px auto;
        }
        @media screen and (max-width: 780px) {
          font-size: 20px;
        }
        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
        @media screen and (max-width: 400px) {
          font-size: 13px;
        }
      }
      .buttons {
        @media screen and (max-width: 780px) {
          flex-direction: column;
          align-items: center;
        }
        .video-play-button {
          @media screen and (max-width: 780px) {
            top: 30px;
          }
        }
      }
    }
  }
}

.features {
  p,
  ul > li {
  }
  ul {
    li {
      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
      @media screen and (max-width: 450px) {
        font-size: 15px;
      }
      @media screen and (max-width: 300px) {
        font-size: 13px;
        text-align: center;
      }
    }
  }
  .featureBuyer2 {
  }
  .flex-revers {
    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }
  .featurebg2 {
  }
  .featureBuyer3 {
  }
  .featurebg3 {
  }
  .default-padding-bottom {
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }
}

.contact {
  .subtitle,
  .main-heading {
    @media screen and (max-width: 780px) {
      text-align: center;
    }
  }
  ul {
    li {
      @media screen and (max-width: 780px) {
        flex-direction: column;
        font-size: 15px;
        padding-bottom: 20px;
      }
      .icon {
        @media screen and (max-width: 780px) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

footer {
  .subtitle {
    @media screen and (max-width: 780px) {
      width: 50%;
    }
    @media screen and (max-width: 500px) {
      width: 90%;
    }
    @media screen and (max-width: 300px) {
      width: 95%;
    }
  }
}
