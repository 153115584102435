$mainFont: "Rubik", sans-serif;
$arabicFont: "Noto Kufi Arabic", sans-serif;
$lineHeight: 1.8;
$mainFontSize: 16px;

$primaryColor: #e84c4f;
$secondaryColor: #fff4f2;
$whiteColor: #fff;
$blackColor: #344356;
$grayColor: #344356c4;
$blueColor: #3498db;
$greenColor: #2ecc71;
$yellowColor: #f1c40f;
$orangeColor: #e67e22;
$purpleColor: #9b59b6;
$tealColor: #008080;
$redColor: #ff0000;
$pinkColor: #ff69b4;
$brownColor: #8b4513;
$lightGrayColor: #d3d3d3;
$darkGrayColor: #7f8c8d;
