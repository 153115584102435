@import url("https://fonts.googleapis.com/css2?family=Mr+De+Haviland&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mr+De+Haviland&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mr+De+Haviland&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,800&family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: aAutoSignature;
  src: url("../../assets/fonts/aAutoSignature.otf");
}
