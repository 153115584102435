.default-padding {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.default-padding-top {
  padding-top: 6rem;
}

.default-padding-bottom {
  padding-bottom: 6rem;
}

.default-margin {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.default-margin-top {
  margin-top: 6rem;
}

.pb-50 {
  padding-bottom: 1rem;
}

.display-flex-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  cursor: pointer;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 20px;
  color: #959595;
}
