.main-content {
  padding: 2rem 6rem;
  background-color: $whiteColor;
  .blogsLists {
    .card {
      background-color: #fff;
      margin-bottom: 10px;
      justify-content: space-around;
      width: 100%;
      &.blog-card {
        display: flex;
        box-shadow: 0 0 10px 10px #e1e1e185;
        overflow: hidden;
        align-items: center;
        flex-direction: row;
        width: 100%;
        @media screen and (max-width: 923px) {
          flex-direction: column !important;
        }
      }
      &:hover img {
        filter: blur(3px);
      }
      img {
        width: 100%;
      }
      .card-main-content {
        display: flex;
        align-items: start;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        span {
          font-size: 13px;
        }
      }
      .cover-image {
        width: 300px;
        @media screen and (max-width: 923px) {
          width: 100%;
        }
      }
      .card-body {
        display: flex;
        padding: 20px;
      }
      .card-title {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        width: 70%;
        padding: 0;
      }
      .card-description {
        font-size: 20px;
        color: $blackColor;
      }
      .more-info {
        a {
          background: #2d4c69;
          padding: 4px 10px;
          padding-bottom: 7px;
          font-size: 15px;
          color: #fff;
          border-radius: 8px;
          display: block;
          font-weight: 600;
          float: right;
          transition: 0.3s;
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 6px 10px $blackColor;
          }
        }
      }
      .buttons {
        position: absolute;
        right: 20px;
        top: 20px;
        display: flex;
        align-items: center;
        gap: 4px;
        .action-btn {
          cursor: pointer;
          background-color: #ffffff8f;
          border-radius: 10px;
          padding: 10px;
        }
      }
    }
  }
  form {
    .form-group {
      margin-bottom: 20px;
      input {
        &::placeholder {
          font-size: 18px;
          color: $blackColor;
          font-style: italic;
        }
      }
    }
  }
}
