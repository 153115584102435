#newBlog {
  position: relative;
  .sun-editor {
    overflow: hidden;
  }
  .css-b62m3t-container {
    width: 100%;
  }
  .blog-title {
    font-weight: 700;
    font-size: 25px;
  }
}
.blogcard {
  border: 1px solid $primaryColor;
  border-radius: 5px;
  padding: 10px;
  .card-image {
    width: 100%;
    margin: auto;
  }
  .card-title {
    font-size: 20px;
    text-align: left;
    padding-top: 20px;
    padding-left: 20px;
    color: $blackColor;
  }
}
.card {
  .card-title {
    padding-top: 10px;
    padding-left: 20px;
  }
}
