.navbar {
  .navbar-brand {
    width: 100px;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navbar-collapse.collapse.show {
    padding: 20px;
    text-align: center;
    .navbar-nav {
      .nav-item {
        margin-bottom: 5px;
        width: 100%;
        color: $blackColor;
        transition: all 0.3s linear;
        font-weight: 500;
        font-size: 20px;
        &:hover {
          letter-spacing: 1px;
        }
      }
    }
  }
}
