#sidebar {
  position: fixed;
  left: -100%;
  top: 0;
  background-color: #fff;
  width: 30%;
  height: 100%;
  z-index: 99999;
  padding: 2rem 30px;
  overflow: scroll;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0px 0 70px #cdcdcd7a;
  &.active {
    left: 0;
  }
  .close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 20px;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }
  .brand-logo {
    width: 200px;
    margin: auto;
    margin-bottom: 40px;
  }
  ul {
    padding-left: 0rem !important;
    li {
      list-style: none;
      margin-bottom: 40px;
      transition: all 0.3s linear;
      a {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        font-size: 2rem;
        text-align: center;
        color: $blackColor;
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: all 0.3s linear;
        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}
