* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $mainFont;
  transition: all 0.5s ease-in;
}

body,
html {
  overflow-x: hidden;
  position: relative;
}

figure {
  margin: 0 0 0 !important;
}

button,
input {
  border: none;
  outline: none;
}

a,
.link {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

.title {
  font-size: 48px;
  text-transform: capitalize;
  color: $blackColor;
}

.main-heading {
  color: $blackColor;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.4em;
}

.btn-custom {
  position: relative;
  max-width: fit-content;
  flex: 20%;
  overflow: hidden;
  border-radius: 30px;

  &.bg-green {
    background-color: $primaryColor !important;
    button {
      color: $whiteColor !important;
    }
    .border {
      background: $primaryColor;
    }
  }

  button {
    width: fit-content;
    position: relative;
    background: rgba(255, 255, 255, 0.27);
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    border: none;
    font-size: 15px;
    outline: none;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    cursor: pointer;
    padding: 10px 50px;
    border: 1px solid $primaryColor;
    color: $primaryColor;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
    transition: color 0.5s ease;
  }

  .border {
    z-index: 0;
    background: $primaryColor;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    position: absolute;
    transition: all 0.5s ease-in-out;
  }

  > .border:nth-of-type(1) {
    top: -35px;
    left: -30px;
  }

  > .border:nth-of-type(2) {
    top: -35px;
    right: -30px;
  }

  > .border:nth-of-type(3) {
    bottom: -35px;
    left: -30px;
  }

  > .border:nth-of-type(4) {
    bottom: -35px;
    right: -30px;
  }

  &:hover .border {
    transform: scale(15.7) rotate(45deg);
  }

  &:hover button {
    color: $whiteColor;
    transition: color 0.3s ease-out;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $whiteColor;
}

::-webkit-scrollbar-thumb {
  background: $primaryColor;
  box-shadow: 0 0 10px $primaryColor;
}

.border-page-time {
  position: relative;

  p {
    font-size: 18px;
    line-height: 1.5;
  }

  a {
    color: #607d8b;
  }

  &:before {
    content: "";
    display: block;
    background: linear-gradient(
      to right top,
      #cddc39 0%,
      #03a9f4 50%,
      #e6f0f5 50%
    );
    height: calc(100% - 100vh + 6px);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: "";
    display: block;
    background: #e6f0f5;
    height: calc(100vh - 6px);
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
  }
}

@mixin overlay-background($color, $duration) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color;
  z-index: 9999;
  display: none;
  &.active {
    display: block;
    animation: Xshow $duration ease;
  }
}

.overlay-background1 {
  @include overlay-background(rgba(0, 0, 0, 0.5), 0.4s);
}

.overlay-background2 {
  @include overlay-background(rgb(255, 255, 255, 0.5), 0.5s);
}

.overlay-background3 {
  @include overlay-background(rgb(255, 0, 76, 0.5), 0.6s);
}

.overlay-background4 {
  @include overlay-background(rgb(228, 16, 27, 0.5), 0.7s);
}

@keyframes Xshow {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

.gray_line {
  background-color: #cdcdcd;
  width: 100%;
  height: 1px;
}

// Uploader
.file-area {
  align-items: center;
  background-color: #e8eef2;
  border: 1px dashed #b9c4ca;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  padding: 45px;
  position: relative;
  text-align: center;
  transition: all 0.3s;
  height: fit-content;

  strong {
    font-size: 12px;
  }

  .file-area__input {
    cursor: pointer;
    height: 100px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .file-area__label,
  .file-area__output {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  .file-area__label {
    color: #2196f3;
    font-weight: 700;
    height: 100%;
  }
  label {
    cursor: pointer;
  }
  .uploader-product-image-file-area__label {
    align-items: center;
    display: flex;
    flex-flow: column;
    font-weight: 400;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  .uploader-product-image-file-area__icon {
    fill: #2196f3;
    height: 30px;
    margin-bottom: 10px;
    width: 40px;
  }
  .uploader-product-image-file-area__label--secondary {
    color: #505b60;
    font-size: 14px;
    margin-top: 10px;
  }
  .upload-icon {
    width: 35px;
  }
  span.uploader-product-media-file-area__label {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 780px) {
      font-size: 18px;
    }
    @media screen and (max-width: 780px) {
      font-size: 13px;
    }
    @media screen and (max-width: 500px) {
      font-size: 8px;
    }
  }
  .form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
    position: absolute;
    opacity: 0;
  }
}
